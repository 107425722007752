/* --------------------------------------------------
   Breakpoint Mixin
   --------------------------------------------------
   Use it in the following form:

   .box {
        padding: 20px;

        @include breakpoint('sm') {
            padding: 80px;
        }
    }

    this will become:

    .box {
        padding: 20px;
    }

    @media (max-width: 499px) {
        .box {
            padding: 80px;
        }
    }
*/
@mixin breakpoint($breakpoint, $direction: $media-direction) {
  // It is checked if the value is present in the Sass Map
  @if map-has-key($breakpoints, $breakpoint) {
    // Output
    @media (#{$direction}-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  }

  // If you have entered a value above, it will be checked here if you have attached a unit. If not, px will be used.
  @else if unitless($breakpoint) {
    $breakpoint: $breakpoint + 0px;

    // Output
    @media (#{$direction}-width: $breakpoint) {
      @content;
    }
  }
}

/* --------------------------------------------------
   Font-Size Mixin
   --------------------------------------------------
   Use it in the following form:

   @include font-size($font-size in px, $line-height in px)

   The $line-height property is optional

*/
@mixin font-size($fs: false, $lh: false) {
  @if $fs {
    font-size: $fs + px;
    font-size: px-to-rem($fs);
  }
  @if $lh {
    line-height: calc($lh/$fs) + em;
  }
}

/* --------------------------------------------------
   Background-Opacity Mixin
   --------------------------------------------------
   Use it in the following form:

   @include background-opacity($color in HEX, $opacity: 0 in 0.n)
*/
@mixin background-opacity($color, $opacity: 0) {
  background: rgba($color, $opacity);
}

@mixin color-opacity($opacity: 0.75) {
  opacity: $opacity;
}
