@import 'styles/utils';

.labelsWrapper {
  display: flex;
  column-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
}

.labelWrapper {
  display: flex;
  align-items: center;
  border-radius: 2px;
}

.icon {
  height: 24px;
  width: 24px;
}

.labelText.labelText {
  padding-right: 5px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
}

.link {
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.noLabels {
  display: none;
}
