@import 'styles/utils';

.headline.headline {
  color: $electric-red;
  margin-top: 20px;
}

.gridContainer {
  justify-content: space-between;
  margin-bottom: 25px;
  & p {
    line-height: 24px;
    color: $grey-80;
  }
}

.businessDaysWrapper {
  width: 82px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  & p {
    font-weight: 700;
  }
}
