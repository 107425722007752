@import 'styles/utils';

.element {
  list-style-type: none;
  margin: 0;
  padding: 13px 20px;
  font-weight: 700;
  line-height: 24px;
  color: $grey-80;
  cursor: pointer;
  &:hover {
    background-color: $light-Grey;
  }
  &._active {
    background-color: $light-Grey;
  }
}

.stationName.stationName {
  font-weight: 700;
  line-height: 24px;
  color: $grey-80;
  margin-right: 10px;
}
