// Interactions Colors
$starcar-yellow: #feed01;
$starcar-yellow-dark: #efdf00;
$starcar-yellow-light: #fff680;
$starcar-yellow-dark-alt: #877d01;
$starcar-yellow-dark-shadow: #b8ae39;

:export {
  colorPrimary: $starcar-yellow;
}

// Functional Colors
$electric-green: #74a422;
$electric-green-dark: #00802b;

$electric-red: #a42222;
$electric-red-alt: #d90000;
$electric-red-dark: #b03525;
$electric-blue: #224fa4;

// Marker Colors
// $marker-premium-organge: #aa9001;
// $marker-electric-green: #67ba03;
// $marker-cdw-icon-grey: #aaaaaa;

// :export {
//   colorPremium: $marker-premium-organge;
//   colorElectric: $marker-electric-green;
// }

// Solid Greys
$black: #000000;
$black-alt: #1a1a1a;
$grey: #333333;
$grey-alt: #666666;
$grey-90: rgba(0, 0, 0, 0.9);
$grey-80: rgba(0, 0, 0, 0.8);
$grey-60: rgba(0, 0, 0, 0.6);
$grey-50: rgba(0, 0, 0, 0.5);
$grey-40: rgba(0, 0, 0, 0.4);
$grey-35: rgba(0, 0, 0, 0.35);
$grey-20: rgba(0, 0, 0, 0.2);
$grey-10: rgba(0, 0, 0, 0.1);
$grey-03: rgba(0, 0, 0, 0.03);
$dark-Grey: #262626;
$mid-Grey: #cccccc;
$card-Grey: #ededed;
$border-Grey: #d6d6d6;
$light-Grey: #f7f7f7;
$light-Grey-alt: #f3f3f3;
$white: #ffffff;
$white-10: rgba(255, 255, 255, 0.1);

:export {
  colorGrey: $grey;
  colorBlack: $black;
  colorBlackAlt: $black-alt;
  colorGreyAlt: $grey-alt;
  colorGrey90: $grey-90;
  colorGrey80: $grey-80;
  colorGrey60: $grey-60;
  colorGrey50: $grey-50;
  colorGrey40: $grey-40;
  colorGrey35: $grey-35;
  colorGrey20: $grey-20;
  // colorGrey12: $grey-12;
  colorGrey10: $grey-10;
  // colorGrey05: $grey-05;
  colorDarkGrey: $dark-Grey;
  colorMidGrey: $mid-Grey;
  colorLightGrey: $light-Grey;
  colorLightGreyAlt: $light-Grey-alt;
  colorCardGrey: $card-Grey;
  colorBorderGrey: $border-Grey;
  colorGrey03: $grey-03;
  colorWhite: $white;
  colorWhite10: $white-10;
}

//Transparent Greys
$grey-transparent-90: rgba(0, 0, 0, 0.9);
$grey-transparent-80: rgba(0, 0, 0, 0.8);
$grey-transparent-70: rgba(0, 0, 0, 0.7);
$grey-transparent-60: rgba(0, 0, 0, 0.6);
$grey-transparent-20: rgba(0, 0, 0, 0.2);
$grey-transparent-10: rgba(0, 0, 0, 0.1);
$grey-transparent-03: rgba(0, 0, 0, 0.03);

:export {
  colorGreyTransparent90: $grey-transparent-90;
  colorGreyTransparent80: $grey-transparent-60;
  colorGreyTransparent60: $grey-transparent-60;
  colorGreyTransparent20: $grey-transparent-20;
  colorGreyTransparent10: $grey-transparent-10;
  colorGreyTransparent03: $grey-transparent-03;
}

:export {
  colorError: $electric-red;
  colorFocus: $grey;
  colorElectro: $electric-green;
}