@import 'styles/utils';

@import '../../../public/fonts/fonts.scss';

.wrapper {
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $dark-Grey;
  background-position: center;
  padding-bottom: 50px;
  @include breakpoint('md') {
    padding: initial;
    height: 750px;
    position: relative;
  }
}

.bprWrapper {
  position: relative;
  width: 100%;
  padding: 0 3% 120px;
  @include breakpoint('md') {
    display: flex;
    padding: 100px 3% 0;
  }
}

.bprDesktop {
  display: none;
  transition: width 0.5s ease;
  min-width: 387px;
  position: relative;
  @include breakpoint('md') {
    display: block;
  }
}

.bprMobile {
  display: block;
  @include breakpoint('md') {
    display: none;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
}

.sloganWrapperMobile {
  position: absolute;
  left: 15%;
  top: 50%;
  transform: translate(-15%, -50%);
  width: 50%;
}

.sloganWrapper {
  display: none;
  transition: width 0.5s ease;
  visibility: 0.2s ease;
  overflow: hidden;
  @include breakpoint('md') {
    display: flex;
    align-items: center;
  }
}

.slogan.slogan {
  font-family: 'Takota';
  font-size: 32px;
  text-align: center;
  transform: skewY(-3deg);
  line-height: 1;
  @include breakpoint('sm') {
    font-size: 42px;
  }
  @include breakpoint('md') {
    font-size: 54px;
  }
  @include breakpoint('xl') {
    font-size: 80px;
  }
  @include breakpoint('xxl') {
    font-size: 100px;
  }
}

.image {
  display: block;
  width: 100%;
  height: 160px;
  object-fit: cover;
  @include breakpoint('md') {
    height: 275px;
  }
}
