@import 'styles/utils';

.consentOverlay {
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  border-radius: 4px;

  .consentOverlay_content {
    display: flex;
    flex-direction: column;
    max-width: fit-content;
    max-height: fit-content;
    align-items: center;
    padding: 30px 20px;
    z-index: 2;
  }

  .bg {
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
}

.backgroundOverlay {
  background-color: $white;
  opacity: 0.8;
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 4px;
}

.title.title {
  font-weight: 900;
  font-size: 32px;
  margin-bottom: 20px;
}

.linkText.linkText {
  color: $grey-80;
  text-decoration: underline;
  line-height: 24px;
}

.infoText.infoText {
  line-height: 24px;
  color: $grey-80;
  margin-bottom: 20px;
}

.button {
  margin: 20px 0;
}
