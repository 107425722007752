@import 'styles/utils';

.flyout {
  position: relative;
  width: 100%;
  margin-top: 10px;
  @include breakpoint('lg') {
    position: absolute;
    width: 94%;
    border-radius: 8px;
    margin-top: 40px;
  }
  z-index: 1000;
  color: $black;
  max-width: calc(1410px * 0.94);
  display: flex;
  justify-content: center;
  background-color: $white;
}

.widgetFlyout {
  position: relative;
  width: 100%;
  margin-top: 10px;
  color: $black;
  max-width: calc(1410px * 0.94);
  display: flex;
  justify-content: center;
  background-color: $white;

  @include breakpoint('sm') {
    &.widgetFlyoutOneWay {
      width: 203%;
    }
  }
}

.mobileCloseIcon {
  height: 24px;
  width: 24px;
  position: absolute;
  right: 15px;
  top: 15px;
  display: block;
  @include breakpoint('lg') {
    display: none;
  }
}

.wrapper {
  width: 100%;
  outline: 1px solid $light-Grey;
  padding-bottom: 20px;
  @include breakpoint('lg') {
    padding-bottom: 0;
    border-radius: 8px;
    //box-shadow: 0 2px 10px 0 $mid-Grey;
  }
}

.widgetWrapper {
  width: 100%;
  outline: 1px solid $light-Grey;
  padding-bottom: 20px;
}

.stationsTitle.stationsTitle {
  padding-left: 15.4px;
  font-size: 14px;
  line-height: 20px;
  color: $grey-80;
}

.list {
  padding: 0;
  overflow-x: hidden;
  max-height: 300px;
  overflow-y: auto;
  margin: 10px 0;
  @include breakpoint('sm') {
    max-height: 350px;
  }
  @include breakpoint('lg') {
    max-height: 500px;
  }
}

.placesTitle.placesTitle {
  padding-left: 15.4px;
  font-size: 14px;
  line-height: 20px;
  margin-top: 16px;
  color: $grey-80;
}

.radiusDisclaimerWrapper {
  padding: 0 20px;
  .title.title {
    line-height: 24px;
    font-weight: 700;
    color: $grey-80;
    margin-bottom: 13px;
  }
  .infoText.infoText {
    line-height: 24px;
    color: $grey-80;
  }
  .link {
    margin: 10px 0 20px;
    color: $grey-80;
    line-height: 24px;
  }
}

.icon {
  height: 24px;
  width: 24px;
  color: $black;
}
