@import 'styles/utils';

.wrapper {
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 8px;
  height: 100%;
}

.stationName.stationName {
  line-height: 30px;
  margin-bottom: 11px;
  display: block;
}

.map {
  img {
    max-width: 100%;
    width: 100%;
    display: block;
    border: 1px solid $mid-Grey;
    border-radius: 4px;
    object-fit: cover;
  }
}
