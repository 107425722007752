// @font-face {
//   font-family: 'Inter';
//   font-style: normal;
//   font-weight: 400;
//   font-display: 'swap';
//   src: url('./inter/Inter-Regular.ttf') format('truetype');
// }

// @font-face {
//   font-family: 'Inter';
//   font-style: normal;
//   font-weight: 700;
//   font-display: 'swap';
//   src: url('./inter/Inter-Bold.ttf') format('truetype');
// }

// @font-face {
//   font-family: 'Inter';
//   font-style: normal;
//   font-weight: 900;
//   font-display: 'swap';
//   src: url('./inter/Inter-Black.ttf') format('truetype');
// }

@font-face {
  font-family: 'Takota';
  font-display: 'swap';
  src: url('./takota/STARCAR-Takota.ttf') format('truetype');
}

@font-face {
  font-family: 'FuturaLT';
  src: url('./futura-lt/FuturaLT.ttf');
  font-weight: 400;
  font-display: 'swap';
  font-style: normal;
}